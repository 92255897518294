import styled from "@emotion/styled";
import { H5, Paragraph2 } from "@styles/Global.styles";

export const DeliveryAddressSelectionSubHeading = styled(H5)`
    margin-bottom: 10px !important;
`

export const DeliveryAddressContainer = styled.div`
    width: 100%;

    .paragraph1 {
        margin-bottom: 5px !important;
    }
`

export const RequireSignatureParagraph = styled(Paragraph2)`
    margin-bottom: 15px !important;
    margin-top: 15px !important;
`